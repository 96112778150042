import { Card, Form, FormInstance, Input, Tabs } from "antd";
import { ChartFormFieldsEnum, TabsKeyEnum } from "../../enum";
import { ABTestingEngagementMemo } from "./engagement";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
import React from "react";
import { AbTestUserDistributionMemo } from "./user-distribution";
import { css } from "@emotion/react";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";

const ShowChartResult: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string>({
    location: LocationTabsView.CHART_TOP,
  });
  const getInitValuesChart = () => {
    const chartForm = getGroupFieldValue("chartForm") ?? {};
    return {
      ...chartForm,
      [ChartFormFieldsEnum.CUSTOM_TIME_RANGE]: chartForm?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) =>
        dayjs(item),
      ),
    };
  };

  return (
    <Form name={FORM_NAME.CHART_FORM} form={form} initialValues={getInitValuesChart()}>
      <Card
        css={css`
          .ant-tabs-tab-active {
            background-color: white !important;
          }
        `}
      >
        <Tabs
          type="card"
          activeKey={items}
          onChange={onChange}
          items={[
            {
              label: "User distribution",
              key: TabsKeyEnum.USER_DISTRIBUTION,
              children: <AbTestUserDistributionMemo form={form} />,
            },
            {
              label: "Engagement",
              key: TabsKeyEnum.ENGAGEMENT,
              children: <ABTestingEngagementMemo form={form} />,
            },
            // {
            //   label: "Monetization",
            //   key: TabsKeyEnum.MONETIZATION,
            //   children: <ABTestingMonetizationMemo form={form} />,
            // },
          ]}
        />
      </Card>

      <Form.Item name={ChartFormFieldsEnum.FILTER_FIELDS} hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
export const ShowChartResultMemo = React.memo(ShowChartResult);
