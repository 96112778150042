import { useCubeQuery } from "@cubejs-client/react";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { useDebounce } from "@src/hooks/use-debounce";
import { config } from "@src/config";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";

export const PlatformFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const productTokenWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const abaIdDebounce = useDebounce(abaIdWatch, config.DEBOUNCE_TIME.SHORT_DELAY);
  const isHasAbaId = abaIdDebounce && typeof abaIdDebounce === "string";

  const { resultSet, isLoading } = useCubeQuery(
    {
      dimensions: [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_AMA_APP_ID],
      filters: [
        {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_ABA_ID,
          operator: "equals",
          values: [abaIdDebounce?.toString()],
        },
      ],
    },
    { skip: !isHasAbaId || !productTokenWatch },
  );

  const options = React.useMemo(() => {
    return resultSet?.rawData()?.map((item: any) => {
      return {
        label: item["user_ab_testing.ama_app_id"]?.slice(-1) === "i" ? "IOS" : "Android",
        value: item["user_ab_testing.ama_app_id"]?.slice(-1),
        amaAppId: item["user_ab_testing.ama_app_id"],
      };
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSet?.rawData()]);

  React.useEffect(() => {
    if (!options) return;
    form.setFieldsValue({
      [FilterFormFieldsEnum.PLATFORM]: options.map((o) => o.value.slice(-1)),
      [FilterFormFieldsEnum.AMA_APP_IDS]: options.map((o) => o.amaAppId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (isHasAbaId) return;
    form.setFieldsValue({ [FilterFormFieldsEnum.PLATFORM]: [] });
  }, [isHasAbaId]);

  return (
    <InputSkeletonLayout
      width={200}
      form={form}
      isLoading={isLoading}
      label="Platform"
      required={true}
      placeholder="Platform"
    >
      <Form.Item label="Platform" name={FilterFormFieldsEnum.PLATFORM} required>
        <SelectWithSpinAndCustomMaxTag
          disabled={!isHasAbaId}
          allowClear
          placeholder="Platform"
          options={options}
          onChange={(_, opt: any) => {
            form.setFieldsValue({ [FilterFormFieldsEnum.AMA_APP_IDS]: opt.map((o: any) => o.amaAppId) });
          }}
          style={{
            width: 200,
          }}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item hidden name={FilterFormFieldsEnum.AMA_APP_IDS}>
        <Input />
      </Form.Item>
    </InputSkeletonLayout>
  );
};
