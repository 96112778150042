import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { MenuProps } from "antd/lib/menu";
import { ExportCSV } from "./export-csv";
import { SQLQuery } from "./sql-query";
import { JSONQuery } from "./json-query";
import { FormInstance } from "antd/lib/form";
import { css } from "@emotion/react";
import { CreateExplorationButton } from "./create-exploration";

export type ChartActionProps = {
  tabKey: string;
  tabName: string;
  form?: FormInstance;
};
export const ChartAction: React.FC<ChartActionProps> = ({ tabKey, tabName, form }) => {
  const items: MenuProps["items"] = [
    {
      label: <ExportCSV tabKey={tabKey} tabName={tabName} />,
      key: "export-csv",
    },
    {
      label: <SQLQuery tabKey={tabKey} tabName={tabName} form={form} />,
      key: "sql-query",
    },
    {
      label: <JSONQuery tabKey={tabKey} tabName={tabName} />,
      key: "json-query",
    },
    {
      label: <CreateExplorationButton tabKey={tabKey} tabName={tabName} />,
      key: "create_exploration",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(originNode) => {
        return (
          <div
            css={css`
              li {
                padding: 0 !important;
              }
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {originNode}
          </div>
        );
      }}
    >
      <Space
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <EllipsisOutlined className="text-[20px] text-black" />
      </Space>
    </Dropdown>
  );
};
