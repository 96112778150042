export const formatColumnTable = (rawTableColumn: any): any[] => {
  return rawTableColumn?.map((item: any) => {
    if (!item?.children) {
      return item;
    }
    return {
      title: "Variant " + item?.shortTitle,
      dataIndex: item?.children?.[0]?.dataIndex,
      key: item?.key + item?.children?.[0]?.key,
    };
  });
};
