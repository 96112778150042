import { Form, Table, Tabs } from "antd";
import React from "react";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { QueryOrder } from "@cubejs-client/core";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { useGetResultQuery } from "@src/hooks/ab-testing/use-get-result-query";
import { UserCountItemProps } from ".";
import { isShowEmpty } from "@src/util/ab-testing/user-count/is-show-empty";
import ReactECharts from "echarts-for-react";
import { formatColumnTable } from "@src/util/ab-testing/user-count/format-column-table";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ChartLayout } from "../../layout/chart-layout";
import { EChartWrapper } from "../../layout/echart-wrapper";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";

const pivotConfig = {
  x: [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE],
  y: [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP, "measures"],
};

const UserCountByInstallDate: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const { setDataSources, setColumns } = useChartWrapperContext();
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { amaAppIds } = getValueOfFiltersForm(filtersFieldWatch);
  const { filters, timeDimensions, isSkip } = mappingQueryUserCount(filtersFieldWatch, chartKey);

  const query = React.useMemo(() => {
    return {
      dimensions: [
        USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
        USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
      ],
      timeDimensions,
      measures: [USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER],
      filters: [
        ...filters,
        {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_UA_INFO_AMA_APP_ID,
          operator: "equals",
          values: amaAppIds,
        },
      ],
      order: {
        [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE]: "asc" as QueryOrder,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), JSON.stringify(timeDimensions), JSON.stringify(amaAppIds)]);

  const { resultSet, isLoading } = useGetResultQuery({
    chartKey: chartKey,
    isSkip: isSkip || !amaAppIds,
    query: query,
    pivotConfig,
  });

  const dataSetMapping: any[] = [];
  const seriesMapping: any[] = [];

  resultSet
    ?.series(pivotConfig)
    ?.sort((a, b) => a?.key?.localeCompare(b?.key))
    ?.forEach((d: any, index) => {
      dataSetMapping.push({ source: (d?.series ?? [])?.map((item: any) => [item?.x, item?.value]) });
      seriesMapping.push({
        type: "line",
        datasetIndex: index,
        name: d?.key?.replace(`,` + USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER, ""),
      });
    });

  const option: echarts.EChartsOption = {
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
    },
    grid: {
      bottom: 100,
      top: 10,
      right: 100,
    },
    dataZoom: [
      {
        type: "slider",
      },
      {
        type: "inside",
      },
    ],
    dataset: dataSetMapping,
    xAxis: { type: "time" },
    yAxis: {},
    series: seriesMapping,
    legend: {
      orient: "vertical",
      align: "right",
      right: 10,
      bottom: 200,
    },
  };

  const columns = formatColumnTable(resultSet?.tableColumns(pivotConfig));

  const dataSource = resultSet?.tablePivot(pivotConfig).map((item: any, index: number) => {
    return {
      id: index,
      ...item,
      [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE]:
        item?.[USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE]?.split("T")?.[0],
    };
  });

  React.useEffect(() => {
    if (isSkip) return;
    setColumns((prev) => ({
      ...prev,
      [chartKey]: columns,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns), isSkip]);

  React.useEffect(() => {
    if (isSkip) return;
    setDataSources((prev) => ({
      ...prev,
      [chartKey]: dataSource,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSource), isSkip]);

  return (
    <ChartLayout loading={!!isLoading}>
      <Tabs
        className="w-full h-full "
        items={[
          {
            key: "chart",
            label: "Chart",
            children: (
              <EChartWrapper isEmpty={isShowEmpty(resultSet)}>
                <ReactECharts option={option} style={{ height: "100%" }} />
              </EChartWrapper>
            ),
          },
          {
            key: "raw-data",
            label: "Raw data",
            children: (
              <EChartWrapper isEmpty={isShowEmpty(resultSet)}>
                <Table
                  id="id"
                  rowKey="id"
                  sticky={{
                    offsetHeader: 0,
                  }}
                  size="small"
                  dataSource={dataSource}
                  columns={columns}
                />
              </EChartWrapper>
            ),
          },
        ]}
      />
    </ChartLayout>
  );
};
export const UserCountByInstallDateMemo = React.memo(UserCountByInstallDate);
