import { useCustomMutation } from "@refinedev/core";
import { useState } from "react";

export const useGetResultMultiStep = (...targetAliases: string[]) => {
  const [result, setResult] = useState<Record<string, any>>({});

  const { mutateAsync, isLoading } = useCustomMutation({
    mutationOptions: {
      onSettled(data, error) {
        if (error) {
          return;
        }
        if (!data?.data.ex) {
          setResult((prev) => ({
            ...prev,
            ...(data?.data.results
              .filter((rs: any) => {
                return !rs.ex && targetAliases.indexOf(rs.alias) !== -1;
              })
              .map((rs: any) => {
                return { [rs.alias]: rs.data };
              })
              .reduce((a: { [key: string]: any }, b: { [key: string]: any }) => {
                return { ...a, ...b };
              }, []) || {}),
          }));
        }
      },
    },
  });

  return {
    mutateAsync,
    isLoading,
    result,
  };
};
