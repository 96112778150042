import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { Form, Input, Popconfirm, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

export const EventName: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();

  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [isFirstGetData, setIsFirstGetData] = React.useState(true);
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { productCode } = getValueOfFiltersForm(filtersFieldWatch);

  const paramsValueWatch = Form.useWatch(ChartFormFieldsEnum.PARAM_VALUE, form) ?? [];
  const { data, isLoading, isFetching } = useCustom({
    method: "get",
    url: `${config.BACKEND_URL}/analytic-events/${productCode}/params`,
    queryOptions: {
      retry: 0,
      enabled: !!productCode,
    },
  });

  const mappingOptionsForEventParams = (items?: any[]) => {
    return (
      items?.map((item: any) => {
        return {
          label: item?.title,
          value: item?.member,
        };
      }) || []
    );
  };
  const eventNameOptions = React.useMemo(() => {
    return data?.data?.map((item: any) => {
      return {
        label: item.event_name,
        value: item.event_name,
        original: item,
      };
    });
  }, [data?.data]);

  React.useEffect(() => {
    if (!eventNameOptions) return;
    if (!isFirstGetData) return;
    const eventParams = eventNameOptions?.find(
      (item: any) => item?.value === form.getFieldValue(ChartFormFieldsEnum.EVENT_NAME),
    )?.original?.event_params;

    const optionEventParams = mappingOptionsForEventParams(eventParams);
    form.setFieldValue(ChartFormFieldsEnum.EVENT_PARAM_OPTIONS, optionEventParams);
    setIsFirstGetData(false);
  }, [eventNameOptions, form, isFirstGetData]);

  const handleChangeEventName = ({ value, eventParams }: { value: string; eventParams: any }) => {
    form.setFieldsValue({
      [ChartFormFieldsEnum.EVENT_NAME]: value,
      [ChartFormFieldsEnum.EVENT_PARAM_OPTIONS]: mappingOptionsForEventParams(eventParams),
      [ChartFormFieldsEnum.EVENT_PARAM]: undefined,
      [ChartFormFieldsEnum.PARAM_VALUE]: undefined,
      [ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT]: false,
    });
    updateSearchUrl();
  };
  return (
    <div>
      <Form.Item label="Event name" name={ChartFormFieldsEnum.EVENT_NAME} required className="mb-1 ">
        <Select
          allowClear
          open={openDropdown}
          onClick={() => {
            setOpenDropdown(true);
          }}
          onBlur={() => {
            setOpenDropdown(false);
          }}
          onChange={(value, option: any) => {
            handleChangeEventName({ value, eventParams: option?.original?.event_params });
            setTimeout(() => {
              setOpenDropdown(false);
            }, 100);
          }}
          loading={isLoading || isFetching}
          showSearch
          placeholder="Select event name"
          options={eventNameOptions}
          style={{ width: 250 }}
          {...(paramsValueWatch?.length > 0 && {
            optionRender: (item: any) => {
              return (
                <Popconfirm
                  title="The data below will be reset."
                  description={"Are you sure you want to change the event name?"}
                  onPopupClick={(e) => {
                    e.stopPropagation();
                  }}
                  onConfirm={() => {
                    handleChangeEventName({ value: item?.value, eventParams: item?.data?.original?.event_params });
                    setOpenDropdown(false);
                  }}
                  onCancel={() => {
                    setOpenDropdown(false);
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    className="event_name_engagement h-[32px]  py-[5px] px-[20px]"
                  >
                    {item.label}
                  </div>
                </Popconfirm>
              );
            },
          })}
        />
      </Form.Item>
      <Form.Item name={ChartFormFieldsEnum.EVENT_PARAM_OPTIONS} hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
