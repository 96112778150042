import { generateBoxPlotQuery, generateHistogramQuery } from "@src/util/ab-testing/time-in-app";

export function boxPlotQueryStep({
  queryStepAlias = "box-plot",
  boxPlotQueryOpts,
}: {
  queryStepAlias?: string;
  boxPlotQueryOpts: {
    userMetricAlias: string;
    userId: string;
    groups: string[];
    metric?: string;
  };
}) {
  return {
    adapter_name: "dbt-like",
    alias: queryStepAlias,
    model_config: {
      query: generateBoxPlotQuery({ ...boxPlotQueryOpts }),
      use_optimized_sql: true,
    },
  };
}

export function histogramQueryStep({
  queryStepAlias = "histogram",
  histogramQueryOpts,
}: {
  queryStepAlias?: string;
  histogramQueryOpts: {
    userMetricAlias: string;
    userId: string;
    groups: string[];
    bucketSize?: number;
    numberOfBuckets?: number;
    metric?: string;
    customMetrics?: Record<string, string>;
  };
}) {
  return {
    adapter_name: "dbt-like",
    alias: queryStepAlias,
    model_config: {
      query: generateHistogramQuery({ ...histogramQueryOpts }),
      use_optimized_sql: true,
    },
  };
}
