import { useCubeQuery } from "@cubejs-client/react";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { formatColumnTable } from "@src/util/ab-testing/user-count/format-column-table";
import React from "react";

type UseGetResultQueryProps = {
  chartKey: string;
  isSkip: boolean;
  query: Record<string, any>;
  pivotConfig: Record<string, any>;
};
export const useGetResultQuery = ({ chartKey, isSkip, query, pivotConfig }: UseGetResultQueryProps) => {
  const prevQuery = React.useRef<Record<string, any>>(query);
  const { setQueries, setResultSets, setColumns, setDataSources } = useChartWrapperContext();

  const isQueryChange = JSON.stringify(prevQuery.current) !== JSON.stringify(query);

  React.useEffect(() => {
    if (isSkip) return;
    prevQuery.current = query;
  }, [isSkip, query]);

  const { resultSet, isLoading } = useCubeQuery(query, {
    skip: !isQueryChange || isSkip,
  });

  const columns = formatColumnTable(resultSet?.tableColumns(pivotConfig));
  const dataSource = resultSet?.tablePivot(pivotConfig);

  React.useEffect(() => {
    if (isSkip) return;
    setQueries((prev) => ({
      ...prev,
      [chartKey]: query,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, isSkip]);

  React.useEffect(() => {
    if (isSkip) return;
    setResultSets((prev) => ({
      ...prev,
      [chartKey]: resultSet,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSet, isSkip]);

  React.useEffect(() => {
    if (isSkip) return;
    setColumns((prev) => ({
      ...prev,
      [chartKey]: columns,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns), isSkip]);

  React.useEffect(() => {
    if (isSkip) return;
    setDataSources((prev) => ({
      ...prev,
      [chartKey]: dataSource,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSource), isSkip]);

  return {
    resultSet,
    isLoading,
  };
};
