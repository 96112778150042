export enum FilterFormFieldsEnum {
  PRODUCT_CODE = "product_code",
  PRODUCT_TOKEN = "product_token",
  PLATFORM = "platform",
  ABA_ID = "aba_id",
  AMA_APP_IDS = "ama_app_ids",
  VARIANTS = "variants",
  INSTALL_DATE = "install_date",
  CUSTOM_INSTALL_DATE = "custom_install_date",
  IS_ABA_ID_CHANGED = "is_aba_id_changed",
  IS_FIRST_APPLIED = "is_first_applied",
}
export enum ChartFormFieldsEnum {
  FILTER_FIELDS = "filters_field",
  TIME_RANGE = "time_range",
  EVENT_NAME = "event_name",
  EVENT_PARAM = "event_param",
  PARAM_VALUE = "param_value",
  CUSTOM_TIME_RANGE = "custom_time_range",
  START_DATE = "start_date",
  END_DATE = "end_date",
  MAX_DAY_DIFF = "max_day_diff",
  AD_UNITS = "ad_units",
  AD_NETWORK = "ad_networks",
  PACKAGE = "package",

  //support
  EVENT_PARAM_OPTIONS = "event_param_options",
  IS_ALLOW_GET_USER_EVENT_ENGAGEMENT = "is_allow_get_user_event_engagement",
}
