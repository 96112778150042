import { Form, InputNumber, Space, Typography } from "antd";
import React from "react";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { QueryOrder } from "@cubejs-client/core";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { useGetResultQuery } from "@src/hooks/ab-testing/use-get-result-query";
import { UserCountItemProps } from ".";
import { ChartLayout } from "../../layout/chart-layout";
import { ChartResultLayout } from "../result-layout";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
const { Text } = Typography;

const pivotConfig = {
  x: [USER_COUNT_CONSTANT.DIMENSIONS.USER_UA_INFO_MEDIA_SOURCE],
  y: [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP, "measures"],
};
const UserCountByMediaSource: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { amaAppIds } = getValueOfFiltersForm(filtersFieldWatch);
  const { filters, timeDimensions, isSkip } = mappingQueryUserCount(filtersFieldWatch, chartKey);

  const [numberOfMediaSource, setNumberOfMediaSource] = React.useState(5);

  const query = React.useMemo(() => {
    return {
      dimensions: [
        USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
        USER_COUNT_CONSTANT.DIMENSIONS.USER_UA_INFO_MEDIA_SOURCE,
      ],
      timeDimensions: timeDimensions,
      measures: [USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER],
      filters: [
        ...filters,
        {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_UA_INFO_AMA_APP_ID,
          operator: "equals",
          values: amaAppIds
        }
      ],
      order: {
        [USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER]: "desc" as QueryOrder,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), JSON.stringify(timeDimensions), JSON.stringify(amaAppIds)]);

  const { resultSet, isLoading } = useGetResultQuery({
    chartKey: chartKey,
    isSkip: isSkip || !amaAppIds,
    query: query,
    pivotConfig,
  });

  const maxNumberOfMediaSource = resultSet
    ? Math.max(...resultSet.series(pivotConfig).map((d) => d.series.length))
    : undefined;

  let maxVal = 0;
  const sourceDataSet =
    resultSet
      ?.series(pivotConfig)
      ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      ?.map((d: any) => {
        return d?.series
          ?.map((item: any) => {
            maxVal = Math.max(maxVal, item?.value);
            return {
              x: item?.x,
              y: d?.key?.replace(`,` + USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER, ""),
              value: item?.value,
            };
          })
          ?.slice(0, numberOfMediaSource);
      })
      ?.flat(1) || [];

  const option: echarts.EChartsOption = {
    tooltip: {
      position: "top",
    },
    grid: {
      height: "50%",
      top: "10%",
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 30,
      },
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: maxVal,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
    },
    dataset: [
      {
        source: sourceDataSet,
      },
    ],
    series: {
      name: "User media source",
      type: "heatmap",
      encode: {},
      label: {
        formatter: (e: any) => {
          return e?.data?.value ?? "-";
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
    label: {
      show: true,
    },
    gradientColor: ["#4bcffa", "#0fbcf9", "#6a89cc", "#4a69bd", "#1e3799"],
  };

  return (
    <ChartLayout loading={!!isLoading}>
      <Space direction="horizontal">
        <Text>Top </Text>
        <InputNumber
          defaultValue={numberOfMediaSource}
          min={1}
          max={maxNumberOfMediaSource}
          onChange={(v) => {
            if (v) {
              setNumberOfMediaSource(v);
            }
          }}
        />
        <Text>Media sources</Text>
      </Space>
      <ChartResultLayout chartKey={chartKey} option={option} pivotConfig={pivotConfig} resultSet={resultSet} />
    </ChartLayout>
  );
};
export const UserCountByMediaSourceMemo = React.memo(UserCountByMediaSource);
