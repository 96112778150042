import { config } from "@src/config";
import { DATE_RANGE_CUSTOM_VALUE } from "@src/config/query-builder";
import dayjs from "dayjs";

export const isValidTimeRange = (timeRange: string | undefined, customTimeRange?: any) => {
  return timeRange === DATE_RANGE_CUSTOM_VALUE
    ? customTimeRange?.length === 2 && !customTimeRange?.some((v: any) => !v)
    : !!timeRange;
};

export const getDateRange = (dateRange: any, type?: "string" | "dayjs") => {
  const isValidCustomTimeRange = dateRange?.length === 2 && !dateRange?.some((v: any) => !v);
  return isValidCustomTimeRange
    ? type === "dayjs"
      ? dateRange?.map((v: string) => dayjs(v))
      : dateRange
    : [null, null];
};

export const getDateRangeWithTime = (timeRange: string | undefined, customTimeRange?: any) => {
  if (!isValidTimeRange(timeRange, customTimeRange)) {
    return undefined;
  }

  const [startDate, endDate] = getDateRange(customTimeRange);

  return timeRange === DATE_RANGE_CUSTOM_VALUE
    ? [dayjs(startDate).format(config.DATE_FORMAT), dayjs(endDate).format(config.DATE_FORMAT)]
    : timeRange;
};
