import { ResultSet } from "@cubejs-client/core";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { UserDistributionTabsKeyEnum } from "@src/pages/ab-test-explore/enum";
import { formatColumnTable } from "@src/util/ab-testing/user-count/format-column-table";
import { Table } from "antd";

type TableWithRawDataProps = {
  chartKey: string;
  resultSet: ResultSet<any> | null;
  pivotConfig: Record<string, any>;
};

export const TableWithRawData: React.FC<TableWithRawDataProps> = ({ chartKey, resultSet, pivotConfig }) => {
  const columns = formatColumnTable(resultSet?.tableColumns(pivotConfig));

  const dataSource = resultSet?.tablePivot(pivotConfig).map((item: any, index: number) => {
    if (chartKey === UserDistributionTabsKeyEnum.INSTALL_DATE) {
      return {
        ...item,
        [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE]:
          item?.[USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE]?.split("T")?.[0],
      };
    }
    return {
      id: index,
      ...item,
    };
  });

  return (
    <Table
      id="id"
      rowKey="id"
      sticky={{
        offsetHeader: 0,
      }}
      size="small"
      dataSource={dataSource}
      columns={columns}
    />
  );
};
