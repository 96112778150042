import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Empty, Form, Popconfirm, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

export const EventParams: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const eventParamsWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_PARAM_OPTIONS, form) ?? [];
  const paramsValueWatch = Form.useWatch(ChartFormFieldsEnum.PARAM_VALUE, form) ?? [];
  const isHasParamsValue = paramsValueWatch.length > 0;

  const eventNameWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_NAME, form);
  return (
    <Form.Item label="Event param" name={ChartFormFieldsEnum.EVENT_PARAM} className="mb-1 ">
      <Select
        allowClear
        open={openDropdown}
        onBlur={() => {
          setOpenDropdown(false);
        }}
        onClick={() => {
          setOpenDropdown(true);
        }}
        onChange={() => {
          updateSearchUrl();
          form.setFieldsValue({
            [ChartFormFieldsEnum.PARAM_VALUE]: [],
            [ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT]: false,
          });
          setTimeout(() => {
            setOpenDropdown(false);
          }, 100);
        }}
        showSearch
        placeholder="Select event param"
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 60 }}
            className="py-0"
            description={
              <span className="text-gray-400">{eventNameWatch ? "No Data" : "No Data. Select event name first"}</span>
            }
          />
        }
        options={eventParamsWatch}
        style={{
          width: 250,
        }}
        {...(isHasParamsValue && {
          optionRender: (item: any) => {
            return (
              <Popconfirm
                title="The params values and chart below will be reset."
                description="Are you sure you want to change the event param?"
                onPopupClick={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={() => {
                  form.setFieldsValue({
                    [ChartFormFieldsEnum.EVENT_PARAM]: item?.value,
                  });
                  updateSearchUrl();
                  setOpenDropdown(false);
                }}
                onCancel={() => {
                  setOpenDropdown(false);
                }}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className="event_name_engagement h-[32px]  py-[5px] px-[20px]"
                >
                  {item.label}
                </div>
              </Popconfirm>
            );
          },
        })}
      />
    </Form.Item>
  );
};
