import { Form } from "antd";
import React from "react";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { QueryOrder } from "@cubejs-client/core";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { UserCountItemProps } from ".";
import { useGetResultQuery } from "@src/hooks/ab-testing/use-get-result-query";
import { ChartLayout } from "../../layout/chart-layout";
import { ChartResultLayout } from "../result-layout";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";

const pivotConfig = {
  x: [USER_COUNT_CONSTANT.DIMENSIONS.USER_ON_BOARDING_SURVEY_AGE],
  y: [USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP, "measures"],
};

const UserCountByAge: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { amaAppIds } = getValueOfFiltersForm(filtersFieldWatch);
  const { filters, timeDimensions, isSkip } = mappingQueryUserCount(filtersFieldWatch, chartKey);

  const query = React.useMemo(() => {
    return {
      dimensions: [
        USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
        USER_COUNT_CONSTANT.DIMENSIONS.USER_ON_BOARDING_SURVEY_AGE,
      ],
      timeDimensions,
      measures: [USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER],
      filters: [
        ...filters,
        {
          member: USER_COUNT_CONSTANT.DIMENSIONS.USER_ON_BOARDING_SURVEY_AMA_APP_ID,
          operator: "equals",
          values: amaAppIds,
        },
      ],
      order: {
        [USER_COUNT_CONSTANT.DIMENSIONS.USER_ON_BOARDING_SURVEY_AGE]: "desc" as QueryOrder,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), JSON.stringify(timeDimensions), JSON.stringify(amaAppIds)]);

  const { resultSet, isLoading } = useGetResultQuery({
    chartKey: chartKey,
    isSkip: isSkip,
    query: query,
    pivotConfig,
  });

  const dataSetMapping: any[] = [];
  const seriesMapping: any[] = [];

  resultSet
    ?.series(pivotConfig)
    ?.sort((a, b) => a?.key?.localeCompare(b?.key))
    ?.forEach((d: any, index) => {
      dataSetMapping.push({ source: (d?.series ?? [])?.map((item: any) => [item?.x, item?.value]) });
      seriesMapping.push({
        type: "bar",
        datasetIndex: index,
        name: d?.key?.replace(`,` + USER_COUNT_CONSTANT.MEASURES.USER_AB_TESTING_DISTINCT_USER, ""),
        label: {
          show: true,
          rotate: 90,
        },
      });
    });

  const option: echarts.EChartsOption = {
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
    },
    grid: {
      bottom: 100,
      top: 10,
      right: 250,
    },
    dataset: dataSetMapping,
    xAxis: { type: "category" },
    yAxis: {},

    series: seriesMapping,
    legend: {
      orient: "vertical",
      align: "right",
      right: 10,
      bottom: 200,
    },
  };

  return (
    <ChartLayout loading={!!isLoading}>
      <ChartResultLayout chartKey={chartKey} option={option} pivotConfig={pivotConfig} resultSet={resultSet} />
    </ChartLayout>
  );
};
export const UserCountByAgeMemo = React.memo(UserCountByAge);
