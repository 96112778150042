import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, InputNumber } from "antd";

export const MaxDateDiff: React.FC<any> = () => {
  const { updateSearchUrl } = useABContext();
  return (
    <Form.Item label="Max Day Diff" name={ChartFormFieldsEnum.MAX_DAY_DIFF} className="ml-2">
      <InputNumber
        placeholder="Max day diff"
        style={{ width: 150 }}
        min={0}
        onChange={() => {
          updateSearchUrl();
        }}
      />
    </Form.Item>
  );
};
