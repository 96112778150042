import { shortenDimensionsQueryStep } from "./query-utils"

export const DEFAULT_USERS_QUERY_ALIAS = "users"

export function abUsersQuery({
    queryStepAlias = DEFAULT_USERS_QUERY_ALIAS,
    filters,
    productCode,
    timeDimensions,
}: {
    queryStepAlias?: string;
    timeDimensions: any;
    filters: any;
    productCode: any;
}) {
    const primaryQueryStep = {
        adapter_name: "cube",
        alias: queryStepAlias,
        model_config: {
            json_query: {
                dimensions: [
                    "user_ab_testing.ama_app_id",
                    "user_ab_testing.exp_group",
                    "user_ab_testing.install_date_tzutc",
                    "user_ab_testing.user_pseudo_id",
                    "user_ab_testing.master_device_id",
                ],
                timeDimensions: timeDimensions,
                filters: filters,
            },
            product_code: productCode,
        },
    }

    const shortenFieldNamesQueryStep = shortenDimensionsQueryStep({
        queryStepAlias: `shorten-${queryStepAlias}`,
        sourceQueryStepAlias: primaryQueryStep.alias,
        shortenNameMapping: {
            ["user_ab_testing__ama_app_id"]: "ama_app_id",
            ["user_ab_testing__exp_group"]: "exp_group",
            ["user_ab_testing__install_date_tzutc"]: "install_date_tzutc",
            ["user_ab_testing__user_pseudo_id"]: "user_pseudo_id",
            ["user_ab_testing__master_device_id"]: "master_device_id",
        }
    })
    return {
        primaryQueryStep,
        shortenFieldNamesQueryStep
    };
}
