import { ProductFilter } from "./product-filter";
import { AbaIdFilter } from "./aba-id-filter";
import { VariantFilter } from "./variant-filter";
import { InstallDateFilter } from "./install-date-filter";
import { PlatformFilter } from "./platform-filter";
import { SubmitButton } from "./submit-button";

import { FormInstance } from "antd/lib/form";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form } from "antd";
import React from "react";

const _ABFilterFormContent: React.FC<{ form: FormInstance<FilterFormInstanceType> }> = ({ form }) => {
  return (
    <div className="flex justify-between">
      <div className="flex flex-wrap">
        <div className="flex mb-2">
          <ProductFilter form={form} />
          <AbaIdFilter form={form} />
          <PlatformFilter form={form} />
          <VariantFilter form={form} />
        </div>
        <div className="flex ">
          <InstallDateFilter />
          <SubmitButton form={form} />
        </div>
      </div>
      <Form.Item name={FilterFormFieldsEnum.IS_FIRST_APPLIED} hidden valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </div>
  );
};

export const ABFilterFormContent = React.memo(_ABFilterFormContent);
