const DIMENSIONS = {
  USER_AB_TESTING_INSTALL_DATE: "user_ab_testing.install_date_tzutc",
  USER_AB_TESTING_EXP_GROUP: "user_ab_testing.exp_group",
  USER_AB_TESTING_ABA_ID: "user_ab_testing.aba_id",
  USER_AB_TESTING_AMA_APP_ID: "user_ab_testing.ama_app_id",

  USER_UA_INFO_AMA_APP_ID: "user_ua_info.ama_app_id",
  USER_UA_INFO_MEDIA_SOURCE: "user_ua_info.media_source",
  USER_UA_INFO_COUNTRY_CODE: "user_ua_info.country_code",

  USER_ON_BOARDING_SURVEY_AMA_APP_ID: "user_on_boarding_survey.ama_app_id",
  USER_ON_BOARDING_SURVEY_AGE: "user_on_boarding_survey.age",
  USER_ON_BOARDING_SURVEY_GENDER: "user_on_boarding_survey.gender",
};
const MEASURES = {
  USER_AB_TESTING_DISTINCT_USER: "user_ab_testing.distinct_user",
};

const USER_COUNT_CONSTANT = { DIMENSIONS, MEASURES };

const FORM_NAME = {
  FILTER_FORM: "filterForm",
  CHART_FORM: "chartForm",
};
const SEARCH_PARAMS_KEY = {
  CONFIG: "config",
};
export { USER_COUNT_CONSTANT, FORM_NAME, SEARCH_PARAMS_KEY };
