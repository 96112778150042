import React from "react";
import { createContext, useContext } from "react";

type ChartWrapperContextType = {
  queries: Record<string, any>;
  setQueries: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  resultSets: Record<string, any>;
  setResultSets: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  dataSources: Record<string, any>;
  setDataSources: React.Dispatch<React.SetStateAction<Record<string, any>>>;

  columns: Record<string, any>;
  setColumns: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};
export const ChartWrapperContext = createContext({} as ChartWrapperContextType);

type IProps = {
  children: React.ReactNode;
};

export const ChartWrapperContextProvider: React.FC<IProps> = ({ children }) => {
  const [queries, setQueries] = React.useState<Record<string, any>>({});
  const [resultSets, setResultSets] = React.useState<Record<string, any>>({});

  const [dataSources, setDataSources] = React.useState<Record<string, any>>({});
  const [columns, setColumns] = React.useState<Record<string, any>>({});

  const values = { queries, setQueries, resultSets, setResultSets, dataSources, columns, setColumns, setDataSources };
  return <ChartWrapperContext.Provider value={values}>{children}</ChartWrapperContext.Provider>;
};

export const useChartWrapperContext = () => useContext(ChartWrapperContext);
