import { css } from "@emotion/react";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { Card, Form, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { FilterFormFieldsEnum } from "../../enum";

type FilterExplorationLayoutProps = {
  children: React.ReactNode;
  form: FormInstance<FilterFormInstanceType>;
  isLoadingGenerateToken: boolean;
};
export const FilterExplorationLayout: React.FC<FilterExplorationLayoutProps> = ({
  children,
  form,
  isLoadingGenerateToken,
}) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const ref = React.useRef<HTMLDivElement>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = React.useState(false);
  const [parentWidth, setParentWidth] = React.useState<string | number>("100%");
  const [cardHeight, setCardHeight] = React.useState(150);

  const [direction, setDirection] = React.useState<"up" | "down">("down");
  const [windowScrollY, setWindowScrollY] = React.useState(0);
  const [scrollGap, setScrollGap] = React.useState(0);

  React.useEffect(() => {
    setCardHeight(cardRef.current?.clientHeight ?? 150);
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const clientTop = ref.current?.getBoundingClientRect().top ?? 0;
      setParentWidth(ref.current?.clientWidth ?? "100%");
      setCardHeight(cardRef.current?.clientHeight ?? 150);
      if (clientTop < 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
    const resizeObserver = new ResizeObserver(() => {
      setParentWidth(ref.current?.clientWidth ?? "100%");
    });

    ref.current && resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", (e) => {
        console.log("remove event scroll ", e);
      });
    };
  }, []);

  const timeoutIdRef = React.useRef<any>(null);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        windowScrollY < window.scrollY ? setDirection("down") : setDirection("up");
        setScrollGap(Math.abs(window.scrollY - windowScrollY));
        setWindowScrollY(window.scrollY);
      }, 50);
    });
    return () => {
      clearTimeout(timeoutIdRef.current);
      window.removeEventListener("scroll", (e) => {
        console.log("window scroll y remove event ", e);
      });
    };
  }, [windowScrollY, timeoutIdRef]);

  return (
    <div
      ref={ref}
      style={{
        minHeight: cardHeight,
      }}
      css={css`
        .ant-card-body {
          padding-bottom: 0;
        }
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      `}
      className="mb-4 "
    >
      <Spin spinning={!!productCodeWatch && isLoadingGenerateToken}>
        <Card
          ref={cardRef}
          {...(isSticky && {
            style: {
              position: "fixed",
              top:
                direction === "down" && windowScrollY - cardHeight > 170
                  ? (cardHeight + 10) * -1
                  : windowScrollY < 350 || scrollGap > 150
                  ? 0
                  : (cardHeight + 10) * -1,
              width: parentWidth,
              zIndex: 1000,
              transition: "top 0.2s ease-in-out",
            },
          })}
          className="overflow-x-scroll"
        >
          {children}
        </Card>
      </Spin>
    </div>
  );
};
