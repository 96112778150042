
export function shortenDimensionsQueryStep({
    queryStepAlias,
    sourceQueryStepAlias,
    shortenNameMapping
}:{
    queryStepAlias: string;
    sourceQueryStepAlias: string;
    shortenNameMapping: {
        [key: string] : string
    };
    
}) {
    return {
        adapter_name: "dbt-like",
        alias: queryStepAlias,
        model_config: {
            query: `
            SELECT ${
                    Object.keys(shortenNameMapping).map((k) => {
                        return `${k} AS ${shortenNameMapping[k]}`
                    }).join(",")
                }
            FROM {{ ref('${sourceQueryStepAlias}') }}
            `,
        },
    };
}

export function fillZeroNumericMetricQueryStep({
    queryStepAlias,
    sourceQueryStepAlias,
    metrics
}:{
    queryStepAlias: string;
    sourceQueryStepAlias: string;
    metrics: string[]
}) {
    return {
        adapter_name: "dbt-like",
        alias: queryStepAlias,
        model_config: {
            query: `
            SELECT * REPLACE(
                    ${
                        metrics.map((m) => {
                            return `COALESCE(${m}, 0) AS ${m}`
                        }).join(",")
                    }
                )
                
            FROM {{ ref('${sourceQueryStepAlias}') }}
            `,
        },
    };
}


export function leftJoinQueryStep({
    queryStepAlias,
    leftQueryStepAlias,
    rightQueryStepAlias,
    selectExp = (userAlias: string, metricsAlias: string) => `${userAlias}.*, ${metricsAlias}.*`,
    joinCondition,
    filterCondition,
    leftAlias = "l",
    rightAlias = "r",
}: {
    queryStepAlias: string,
    leftQueryStepAlias: string,
    rightQueryStepAlias: string,
    selectExp?: (left: string, right: string) => string,
    joinCondition: {
        on?: (left: string, right: string) => string;
        using?: () => string[]
    },
    filterCondition?: (left: string, right: string) => string,
    leftAlias?: string,
    rightAlias?: string,
}) {
    let joinClause = ""

    if (joinCondition.on) {
        joinClause = `ON ${joinCondition.on(leftAlias, rightAlias)}`
    } else if (joinCondition.using) {
        joinClause = `USING (${joinCondition.using().join(",")})`
    }

    if (joinClause.length === 0) throw Error("Invalid join condition")

    return {
        adapter_name: "dbt-like",
        alias: queryStepAlias,
        model_config: {
            query: `
            SELECT ${selectExp(leftAlias, rightAlias)}
            FROM {{ ref('${leftQueryStepAlias}') }} ${leftAlias}
            LEFT JOIN {{ ref('${rightQueryStepAlias}') }} ${rightAlias} ${joinClause}
            WHERE ${filterCondition ? filterCondition(leftAlias, rightAlias) : "TRUE"}
            `,
        },
    };
}


export function aggregateQueryStep({
    queryStepAlias,
    sourceQueryStepAlias,
    groups,
    metrics
}:{
    queryStepAlias: string;
    sourceQueryStepAlias: string;
    groups: string[]
    metrics: {[key: string]: string}
}) {
    return {
        adapter_name: "dbt-like",
        alias: queryStepAlias,
        model_config: {
            query: `
            SELECT ${groups.join(", ")}, ${Object.keys(metrics).map((m) => `${metrics[m]} AS ${m}`).join(", ")}
            FROM {{ ref('${sourceQueryStepAlias}') }}
            GROUP BY ${groups.map((g, idx) => idx + 1).join(", ")}
            `,
        },
    };
}

