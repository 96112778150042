import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form";

export const ApplyEventButton: React.FC<{ form: FormInstance }> = ({ form }) => {
  const eventName = Form.useWatch(ChartFormFieldsEnum.EVENT_NAME, form);
  const eventParams = Form.useWatch(ChartFormFieldsEnum.EVENT_PARAM, form);
  const paramsValues = Form.useWatch(ChartFormFieldsEnum.PARAM_VALUE, form);
  const isShow = Form.useWatch(ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT, form);
  const isValidEventParamsValues = eventParams ? eventParams && isArrayAndNotEmpty(paramsValues) : true;

  return (
    <Button
      type="primary"
      disabled={eventName ? isShow || !isValidEventParamsValues : !eventName}
      onClick={() => {
        form.setFieldValue(ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT, true);
      }}
    >
      Apply
    </Button>
  );
};
