import { css } from "@emotion/react";
import { DATE_RANGE_CUSTOM_VALUE, DATE_RANGES } from "@src/config/query-builder";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { DatePicker, Form, Select } from "antd";
import React from "react";

const { RangePicker } = DatePicker;

export const InstallDateFilter: React.FC<any> = () => {
  const installTimeWatch = Form.useWatch(FilterFormFieldsEnum.INSTALL_DATE);

  const options = DATE_RANGES.map((m) => {
    return {
      label: m.title || m.value,
      value: m.value,
    };
  });
  return (
    <div className="ml-2 flex">
      <Form.Item label="Install Date" name={FilterFormFieldsEnum.INSTALL_DATE} className="mb-1 w-[150px]">
        <Select
          allowClear
          placeholder="Install date"
          options={options}
          style={{
            width: 150,
          }}
          optionRender={(item) => {
            return item.label;
          }}
        />
      </Form.Item>
      {installTimeWatch === DATE_RANGE_CUSTOM_VALUE && (
        <Form.Item
          css={css`
            .ant-form-item-label {
              opacity: 0;
            }
          `}
          className="ml-2"
          required
        >
          <Form.Item
            label="Custom Install Date"
            name={FilterFormFieldsEnum.CUSTOM_INSTALL_DATE}
            className="mb-0"
            rules={[
              {
                validator(rule, value, callback) {
                  if (!value || value.length !== 2) {
                    return Promise.reject("Please select date range");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <RangePicker style={{ width: 250 }} />
          </Form.Item>
        </Form.Item>
      )}
    </div>
  );
};
