import { useCubeQuery } from "@cubejs-client/react";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { config } from "@src/config";
import { useDebounce } from "@src/hooks/use-debounce";
import { ChartFormFieldsEnum, EngagementTabsKeyEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { getDateRangeWithTime, isValidTimeRange } from "@src/util/time-form";
import { Button, Form, Spin, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { uniqBy } from "lodash";
import React from "react";
import { Typography } from "antd";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";
import { getParamsFieldValue } from "@src/util/ab-testing/params";

const { Text } = Typography;

export const ParamValues: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();
  const fieldFilter = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { productCode, abaId, variants, installDate, customInstallDate, amaAppIds } =
    getValueOfFiltersForm(fieldFilter);
  const abaIdDebounce = useDebounce(abaId, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const variantsDebounce = useDebounce(variants, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const amaAppIdsDebounce = useDebounce(amaAppIds, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const installDateDebounce = useDebounce(installDate, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const customInstallDateDebounce = useDebounce(customInstallDate, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const eventNameWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_NAME, form);
  const eventNameWatchDebounce = useDebounce(eventNameWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const eventParamsWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_PARAM, form);
  const eventParamsWatchDebounce = useDebounce(eventParamsWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const timeRangeWatch = Form.useWatch(ChartFormFieldsEnum.TIME_RANGE, form);
  const timeRangeWatchDebounce = useDebounce(timeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const customTimeRangeWatch = Form.useWatch(ChartFormFieldsEnum.CUSTOM_TIME_RANGE, form);
  const customTimeRangeWatchDebounce = useDebounce(customTimeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const maxDayDiff = Form.useWatch(ChartFormFieldsEnum.MAX_DAY_DIFF, form);
  const maxDayDiffDebounce = useDebounce(maxDayDiff, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const [options, setOptions] = React.useState<any[]>([]);
  const [offset, setOffset] = React.useState(0);
  const [isFinish, setIsFinish] = React.useState(false);

  //search
  const searchValueRef = React.useRef("");
  const [optionsSearchSelected, setOptionsSearchSelected] = React.useState<any[]>([]);
  const [isSearchMode, setIsSearchMode] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string | undefined>();

  const searchValueDebounce = useDebounce(searchValue, config.DEBOUNCE_TIME.SHORT_DELAY);

  const [isFirstEventParamsChange, setIsFirstEventParamsChange] = React.useState(true);
  const limit = 20;

  const paramsValues = form.getFieldValue(ChartFormFieldsEnum.PARAM_VALUE);

  const baseQuery = React.useMemo(() => {
    return {
      measures: ["firebase.user_count", "firebase.event_count"],
      timeDimensions: [
        installDateDebounce
          ? {
              dimension: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
              dateRange: getDateRangeWithTime(installDateDebounce, customInstallDateDebounce),
            }
          : null,
        {
          dimension: "firebase.event_timestamp",
          dateRange: getDateRangeWithTime(timeRangeWatchDebounce, customTimeRangeWatchDebounce),
        },
      ].filter((f) => f !== null),
      order: {
        "firebase.user_count": "desc",
      },
      dimensions: [eventParamsWatchDebounce],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: [eventNameWatchDebounce],
        },
        abaIdDebounce
          ? {
              member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_ABA_ID,
              operator: "equals",
              values: [abaIdDebounce],
            }
          : null,
        variantsDebounce && variantsDebounce?.length > 0
          ? {
              member: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_EXP_GROUP,
              operator: "equals",
              values: variantsDebounce,
            }
          : null,
        amaAppIdsDebounce && amaAppIdsDebounce?.length > 0
          ? {
              member: "firebase.ama_app_id",
              operator: "equals",
              values: amaAppIdsDebounce,
            }
          : null,
        {
          member: eventParamsWatchDebounce,
          operator: "set",
        },
        maxDayDiffDebounce > 0
          ? {
              and: [
                {
                  member: "firebase.day_diff",
                  operator: "lte",
                  values: [maxDayDiffDebounce],
                },
                {
                  member: "firebase.day_diff",
                  operator: "gte",
                  values: [0],
                },
              ],
            }
          : null,
      ].filter((f) => f !== null),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customTimeRangeWatchDebounce,
    eventNameWatchDebounce,
    eventParamsWatchDebounce,
    timeRangeWatchDebounce,
    installDateDebounce,
    customInstallDateDebounce,
    amaAppIdsDebounce,
    abaIdDebounce,
    variantsDebounce,
    maxDayDiffDebounce,
  ]);

  const query: any = React.useMemo(() => {
    return {
      ...baseQuery,
      offset: offset,
      limit: limit,
    };
  }, [baseQuery, offset]);

  const queryGetDefault = React.useMemo(() => {
    return {
      ...baseQuery,
      filters: baseQuery.filters.concat(
        paramsValues
          ? [
              {
                member: eventParamsWatchDebounce,
                operator: "contains",
                values: paramsValues,
              },
            ]
          : [],
      ),
    };
  }, [baseQuery, eventParamsWatchDebounce, JSON.stringify(paramsValues)]);

  const querySearch = React.useMemo(() => {
    return {
      ...baseQuery,
      offset: 0,
      limit: 100,
      filters: baseQuery.filters.concat(
        searchValueDebounce
          ? [
              {
                member: eventParamsWatchDebounce,
                operator: "contains",
                values: [searchValueDebounce],
              },
            ]
          : [],
      ),
    };
  }, [baseQuery, searchValueDebounce, eventParamsWatchDebounce]);

  const currentTabsParams: string[] | undefined = getParamsFieldValue(TabsViewParamsEnum.COLLAPSE_ITEMS, "tabsConfig");

  const isSkip =
    !currentTabsParams?.includes(EngagementTabsKeyEnum.EVENT_COUNT_BREAKDOWN) ||
    !eventParamsWatchDebounce ||
    !isValidTimeRange(timeRangeWatchDebounce, customTimeRangeWatchDebounce) ||
    !amaAppIdsDebounce ||
    !productCode;

  const { resultSet: resultSetDefault, isLoading: isLoadingDefault } = useCubeQuery(queryGetDefault as any, {
    skip: isSkip || !isFirstEventParamsChange || !isArrayAndNotEmpty(paramsValues),
  });

  const { resultSet, isLoading } = useCubeQuery(query, {
    skip: isSkip,
  });

  const { resultSet: resultSetSearch, isLoading: isLoadingSearch } = useCubeQuery(querySearch as any, {
    skip: isSkip || !isSearchMode || !searchValueDebounce || searchValueDebounce === "",
  });

  const mappingOptions = (rawData?: any[]) => {
    return (
      rawData?.map((item: any) => {
        return {
          label: item[eventParamsWatchDebounce]?.toString(),
          value: item[eventParamsWatchDebounce],
          user_count: item?.["firebase.user_count"],
          event_count: item?.["firebase.event_count"],
        };
      }) ?? []
    );
  };

  const optionsSearch = mappingOptions(resultSetSearch?.rawData());

  React.useEffect(() => {
    if (!resultSetDefault?.rawData()) return;
    const newOpt = mappingOptions(resultSetDefault?.rawData());
    setOptions((prev) => uniqBy([...prev, ...newOpt], (item) => item.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSetDefault?.rawData()]);

  React.useEffect(() => {
    if (!resultSet?.rawData()) return;
    if (resultSet?.rawData()?.length < limit) {
      setIsFinish(true);
    }
    const newOpt = mappingOptions(resultSet?.rawData());
    setOptions((prev) => uniqBy([...prev, ...newOpt], (item) => item.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSet?.rawData()]);

  //reset when change event params
  const triggerChangeDependencies = JSON.stringify([
    eventParamsWatchDebounce,
    abaIdDebounce,
    variantsDebounce,
    installDateDebounce,
    customInstallDateDebounce,
    maxDayDiffDebounce,
  ]);
  React.useEffect(() => {
    if (eventNameWatchDebounce) {
      setIsFirstEventParamsChange(false);
    }
    form.setFieldValue(ChartFormFieldsEnum.PARAM_VALUE, []);
    setOffset(0);
    setOptions([]);
    setIsFinish(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerChangeDependencies, form]);
  const isShowLoadMore = !isSearchMode && !isFinish && options.length > 0;

  const loading = isLoading || isLoadingSearch || isLoadingDefault;
  return (
    <Form.Item label="Param values" name={ChartFormFieldsEnum.PARAM_VALUE} className="mb-1" required>
      <SelectWithSpinAndCustomMaxTag
        loading={loading}
        showSearch
        allowClear
        placeholder="Select param values"
        onSearch={(v) => {
          setIsSearchMode(true);
          searchValueRef.current = v;
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            setSearchValue(searchValueRef.current);
          }
        }}
        onBlur={() => {
          setIsSearchMode(false);
          searchValueRef.current = "";
          setSearchValue(undefined);

          if (optionsSearchSelected.length > 0) {
            setOptions((prev) => uniqBy([...optionsSearchSelected, ...prev], (item) => item.value));
            setOptionsSearchSelected([]);
          }
        }}
        onSelect={(_, option) => {
          setOptionsSearchSelected((prev) => [...prev, option]);
        }}
        onDeselect={(value) => {
          setOptionsSearchSelected((prev) => prev.filter((_item: any) => _item?.value !== value));
        }}
        options={isSearchMode ? optionsSearch : options}
        style={{
          width: 400,
        }}
        onChange={() => {
          form.setFieldValue(ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT, false);
          updateSearchUrl();
        }}
        optionRender={(item: any) => {
          return (
            <Tooltip title={item.label} mouseLeaveDelay={0}>
              <div className="flex justify-between">
                <span className="truncate max-w-[150px]">{item.label}</span>
                <span className="mr-4">
                  <div className="content-start w-[180px]">
                    <Text disabled>
                      {Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                      }).format(item?.data?.user_count)}{" "}
                      users
                    </Text>
                    <Text disabled> / </Text>
                    <Text disabled>
                      {Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                      }).format(item?.data?.event_count)}{" "}
                      events
                    </Text>
                  </div>
                </span>
              </div>
            </Tooltip>
          );
        }}
        mode="multiple"
        dropdownRender={(ele) => {
          return (
            <div>
              <Spin spinning={loading}>{ele}</Spin>
              {isShowLoadMore && (
                <div className="flex justify-center">
                  <Button
                    type="link"
                    disabled={loading}
                    onClick={() => {
                      setOffset((prev) => prev + limit);
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      />
    </Form.Item>
  );
};
