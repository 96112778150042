import { Tabs } from "antd";
import { EChartWrapper } from "../layout/echart-wrapper";
import { isShowEmpty } from "@src/util/ab-testing/user-count/is-show-empty";
import ReactECharts from "echarts-for-react";
import { TableWithRawData } from "./table/table-with-raw-data";
import { ResultSet } from "@cubejs-client/core";

type ChartResultLayoutProps = {
  chartKey: string;
  resultSet: ResultSet<any> | null;
  pivotConfig: Record<string, any>;
  option: echarts.EChartsOption;
};
export const ChartResultLayout: React.FC<ChartResultLayoutProps> = ({ chartKey, option, pivotConfig, resultSet }) => {
  return (
    <Tabs
      className="w-full h-full "
      items={[
        {
          key: "chart",
          label: "Chart",
          children: (
            <EChartWrapper isEmpty={isShowEmpty(resultSet)}>
              <ReactECharts option={option} style={{ height: "100%" }} />
            </EChartWrapper>
          ),
        },
        {
          key: "raw-data",
          label: "Raw data",
          children: (
            <EChartWrapper isEmpty={isShowEmpty(resultSet)}>
              <TableWithRawData chartKey={chartKey} pivotConfig={pivotConfig} resultSet={resultSet} />
            </EChartWrapper>
          ),
        },
      ]}
    />
  );
};
