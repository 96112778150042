export function generateBoxPlotQuery({
  userMetricAlias,
  userId = "user_id",
  groups = ["exp_group"],
  metric = "number_metric",
}: {
  userMetricAlias: string;
  userId: string;
  groups: string[];
  metric?: string;
}) {
  return `
      with base as (
          SELECT ${userId} as user_id, ${metric} AS number_metric, *
          FROM {{ ref('${userMetricAlias}') }}
      )
      SELECT ${groups.join(", ")},
          MIN(number_metric) min,
          APPROX_QUANTILES(number_metric, 100)[OFFSET(25)] Q1,
          APPROX_QUANTILES(number_metric, 100)[OFFSET(50)] median,
          APPROX_QUANTILES(number_metric, 100)[OFFSET(75)] Q3,
          APPROX_QUANTILES(number_metric, 100)[OFFSET(90)] P90,
          APPROX_QUANTILES(number_metric, 100)[OFFSET(95)] P95,
          MAX(number_metric) as max,
      FROM base
      GROUP BY 1
      `;
}

export function generateHistogramQuery({
  userMetricAlias,
  userId = "user_id",
  groups = ["exp_group"],
  bucketSize = 10,
  numberOfBuckets = 30,
  metric = "number_metric",
}: {
  userMetricAlias: string;
  userId: string;
  groups: string[];
  bucketSize?: number;
  numberOfBuckets?: number;
  metric?: string;
}): string {
  /*
      userMetricAlias: query step alias 
          requires: user_id, number_metric, exp_group
      */

  return `
      {% set bucket_size = ${bucketSize} %}
      {% set number_of_buckets = ${numberOfBuckets} %}
  
      with base as (
          SELECT ${userId} as user_id, ${metric} AS number_metric, *
          FROM {{ ref('${userMetricAlias}') }}
      ),
      scaffolding AS (
          SELECT *
          FROM (
              SELECT bucket_idx,
              bucket_idx * {{ bucket_size }} AS lower_bound,
              (bucket_idx + 1) * {{ bucket_size }} AS upper_bound,
              CASE
                WHEN {{ bucket_size }} > 1 THEN "[" || bucket_idx * {{ bucket_size }} || ", " || (bucket_idx + 1) * {{ bucket_size }} || ")"
                WHEN {{ bucket_size }} = 1 THEN CAST(bucket_idx * {{ bucket_size }} AS STRING)
              END AS bucket_name,
              FROM UNNEST(GENERATE_ARRAY(0, {{ number_of_buckets }} - 1)) bucket_idx
              UNION 
              SELECT {{ number_of_buckets }},
              {{ bucket_size }} * {{ number_of_buckets }} AS lower_bound,
              CAST(NULL AS INT64) AS upper_bound,
              {{ bucket_size }} * {{ number_of_buckets }} || "<=" AS bucket_name
          ) buckets CROSS JOIN (
              SELECT distinct ${groups.join(", ")}
              FROM base
          ) groups
      ),
      histogram AS (
          SELECT ${groups.map((g) => `scaffolding.${g}`).join(", ")}, 
              scaffolding.bucket_idx,
              scaffolding.bucket_name, 
              COALESCE(COUNT(DISTINCT base.user_id), 0) as distinct_users,
          FROM scaffolding
          LEFT JOIN base 
              ON scaffolding.bucket_idx = LEAST(DIV(SAFE_CAST(base.number_metric AS NUMERIC), SAFE_CAST({{ bucket_size }} AS NUMERIC)), {{number_of_buckets}})
              AND ${groups.map((g) => `scaffolding.${g} = base.${g}`).join(" AND ")}
          GROUP BY 1, 2, ${groups.map((_, idx) => idx + 3).join(", ")}
      )
      SELECT *, ROUND(
          COALESCE(
          SAFE_DIVIDE(
              distinct_users, 
              SUM(distinct_users) OVER (PARTITION BY exp_group))
          , 0
          ) * 100
      , 2) distinct_users_percent
      FROM histogram
  
      `;
}

export function prepareHistogramLayout({
  variants,
  defaultGrids,
  defaultXAxes,
  defaultYAxes,
  defaultSeries,
  defaultTitles,
}: {
  variants: string[];
  defaultGrids?: echarts.GridComponentOption[];
  defaultXAxes?: echarts.XAXisComponentOption[];
  defaultYAxes?: echarts.YAXisComponentOption[];
  defaultSeries?: echarts.SeriesOption[];
  defaultTitles?: echarts.TitleComponentOption[];
}) {
  const grids: echarts.GridComponentOption[] =
    defaultGrids ||
    Array(variants.length).fill({
      show: true,
      borderWidth: 0,
      shadowColor: "rgba(0, 0, 0, 0.3)",
      shadowBlur: 2,
    });
  const xAxes: echarts.XAXisComponentOption[] =
    defaultXAxes ||
    Array(variants.length).fill({
      type: "category",
      axisLabel: {
        rotate: 90,
      },
    });
  const yAxes: echarts.YAXisComponentOption[] =
    defaultYAxes ||
    Array(variants.length).fill({
      name: "% Users",
      type: "value",
      min: 0,
      max: 100,
      offset: 15,
      nameGap: 40,
      nameTextStyle: {
        padding: [0, 50, 0, 0],
        fontSize: 14,
        fontWeight: "bold",
      },
    });
  const series: echarts.SeriesOption[] =
    defaultSeries ||
    Array(variants.length).fill({
      type: "bar",
      barWidth: "100%",
      encode: {
        y: "distinct_users_percent",
        x: "bucket_name",
      },
      markPoint: {
        data: [{ type: "max", name: "Max" }],
      },
    });
  const titles: echarts.TitleComponentOption[] =
    defaultTitles ||
    Array(variants.length).fill({
      textAlign: "center",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
      },
    });

  const colCount = 2;
  const rowCount = Math.ceil(variants.length / colCount);
  const containerGrid = {
    top: 15,
    left: 5,
    right: 5,
    bottom: 0,
  };
  const xGapPercent = 8;
  const yGapPercent = 15;
  const getInnerGrid = (idx: number) => {
    const x = idx % colCount;
    const y = Math.floor(idx / colCount);
    const containerWidth = 98;
    const containerHeight = 100 - containerGrid.top - containerGrid.bottom;
    const top = containerGrid.top + (containerHeight / rowCount) * y;
    const left = containerGrid.left + (containerWidth / colCount) * x;

    const width = containerWidth / colCount - xGapPercent;
    const height = containerHeight / rowCount - yGapPercent;

    return {
      top: `${top}%`,
      left: `${left}%`,
      width: `${width}%`,
      height: `${height}%`,
    };
  };

  // let count = 0;

  variants.forEach(function (variant, idx) {
    const innerGrid = getInnerGrid(idx);
    grids[idx] = {
      ...grids[idx],
      ...innerGrid,
    };

    xAxes[idx] = {
      ...xAxes[idx],
      gridIndex: idx,
      id: `xAxis-${variant}`,
    };

    yAxes[idx] = {
      ...yAxes[idx],
      gridIndex: idx,
      id: `yAxis-${variant}`,
    };

    series[idx] = {
      name: variant,
      datasetIndex: idx + 1,
      xAxisIndex: idx,
      yAxisIndex: idx,
      ...series[idx],
    };

    titles[idx] = {
      text: `Variant: ${variant}`,
      left: parseFloat(innerGrid.left) + parseFloat(innerGrid.width) / 4 + "%",
      top: parseFloat(innerGrid.top) - 4 + "%",
    };
  });

  return {
    title: titles.concat([
      {
        text: "Histogram",
        top: "top",
        left: "left",
      },
    ]),
    grid: grids,
    xAxis: xAxes,
    yAxis: yAxes,
    series: series,
  };
}
