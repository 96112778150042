import { Button, Modal } from "antd";
import { ChartActionProps } from ".";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import React from "react";
import JsonViewer from "@src/components/query-builder/json-viewer";
import { DeploymentUnitOutlined } from "@ant-design/icons";

export const JSONQuery: React.FC<ChartActionProps> = ({ tabKey }) => {
  const { queries } = useChartWrapperContext();
  const [open, setOpen] = React.useState(false);
  const query = queries[tabKey];

  return (
    <div>
      <Button
        type="text"
        disabled={!query}
        onClick={() => setOpen(true)}
        className="w-full flex justify-start items-center"
      >
        <DeploymentUnitOutlined className="pb-1 text-[16px]" />
        View JSON Query
      </Button>
      <Modal
        title="JSON Query"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => {
          setOpen(true);
        }}
        width={800}
      >
        <div className="h-[600px] overflow-y-scroll">
          <JsonViewer query={query} />
        </div>
      </Modal>
    </div>
  );
};
