import { FormInstance } from "antd/lib/form";
import { EngagementTabsKeyEnum, TabsKeyEnum } from "@src/pages/ab-test-explore/enum/tabs-key.enum";
import React from "react";

import { UserEventHistogram } from "./component/event-breakdown/user-event-histogram";
import { Checkbox, Collapse, Form } from "antd";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { TooltipChartLayout } from "../../layout/tooltip-chart-layout";
import { MessageDescription } from "@src/constant/explore/message-description";
import { ApplyEventButton, EventName, EventParams, ParamValues } from "./component/form";

const EventBreakdown: React.FC<{ form: FormInstance }> = ({ form }) => {
  const eventParams = Form.useWatch(ChartFormFieldsEnum.EVENT_PARAM, form);

  return (
    <Collapse
      className="mt-4 ab-testing-collapse"
      defaultActiveKey={[EngagementTabsKeyEnum.EVENT_COUNT_BREAKDOWN]}
      items={[
        {
          key: EngagementTabsKeyEnum.EVENT_COUNT_BREAKDOWN,
          label: <TooltipChartLayout label="Event breakdown" content={MessageDescription.ENGAGEMENT.EVENT_BREAKDOWN} />,
          children: (
            <div className="relative">
              <div className="flex flex-wrap ">
                <EventName form={form} />
                <div className="px-2" />
                <EventParams form={form} />
                <div className="px-2" />
                {eventParams && <ParamValues form={form} />}
              </div>
              <div className="ml-8 absolute top-0 right-0">
                <ApplyEventButton form={form} />
              </div>
              <UserEventHistogram form={form} chartKey={TabsKeyEnum.EVENT_BREAKDOWN} />
              <Form.Item name={ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT} hidden valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </div>
          ),
          extra: null,
        },
      ]}
    />
  );
};
export const EventBreakdownMemo = React.memo(EventBreakdown);
