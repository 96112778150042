import { useCubeQuery } from "@cubejs-client/react";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";

export const AbaIdFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const productTokenWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);

  const { resultSet, isLoading } = useCubeQuery(
    {
      dimensions: ["user_ab_testing.aba_id"],
      order: [["user_ab_testing.aba_id", "desc"]],
      // limit: 5
    },
    { skip: !productTokenWatch },
  );

  const options = isLoading
    ? []
    : resultSet?.rawData().map((item: any) => {
        return {
          label: `Aba Id: ${item["user_ab_testing.aba_id"]}`,
          value: item["user_ab_testing.aba_id"],
        };
      });

  return (
    <InputSkeletonLayout
      width={200}
      form={form}
      isLoading={isLoading}
      label="Aba Id"
      required={true}
      placeholder="Select Aba Id"
    >
      <Form.Item label="Aba Id" name={FilterFormFieldsEnum.ABA_ID} required>
        <Select
          disabled={!productTokenWatch}
          allowClear
          placeholder="Select Aba Id"
          options={options}
          showSearch
          filterOption={(input, option) => {
            const { label, value } = option as any;
            if (!value || !label) return false;
            return (
              label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={() => {
            form.setFieldsValue({ [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: true });
          }}
          style={{
            width: 200,
          }}
        />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_ABA_ID_CHANGED} valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>
    </InputSkeletonLayout>
  );
};
