import { FilterFormFieldsEnum } from "../enum";
import { FilterFormFields } from "../types";

export const getValueOfFiltersForm = (filterFields?: FilterFormFields) => {
  const {
    [FilterFormFieldsEnum.PRODUCT_CODE]: productCode,
    [FilterFormFieldsEnum.PRODUCT_TOKEN]: productToken,
    [FilterFormFieldsEnum.ABA_ID]: abaId,
    [FilterFormFieldsEnum.VARIANTS]: variants,
    [FilterFormFieldsEnum.INSTALL_DATE]: installDate,
    [FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]: customInstallDate,
    [FilterFormFieldsEnum.PLATFORM]: platforms,
    [FilterFormFieldsEnum.AMA_APP_IDS]: amaAppIds,
  } = filterFields ?? {};

  return {
    productCode,
    productToken,
    abaId,
    variants,
    installDate,
    customInstallDate,
    platforms,
    amaAppIds,
  };
};
