import React from "react";
import { List } from "@refinedev/antd";
import { FilterExploration } from "./component/filter/filter-exploration";

import { ShowChartResultMemo } from "./component/charts/show-chart-result";
import { FilterFormFieldsEnum } from "./enum";
import { Form } from "antd";
import { ManageTabsContextProvider } from "@src/contexts/ab-testing/manage-tabs-context";
import { useABContext } from "@src/contexts/ab-testing/ab-context";

export const ABTestExplore: React.FC = () => {
  const { filterForm, chartForm } = useABContext();

  const isUserAppliedFilter = Form.useWatch(FilterFormFieldsEnum.IS_FIRST_APPLIED, filterForm);
  return (
    <List
      title={<span>A/B Test Exploration</span>}
      headerProps={{
        extra: false,
      }}
    >
      <FilterExploration form={filterForm} chartFormInstance={chartForm} />
      {isUserAppliedFilter && (
        <ManageTabsContextProvider>
          <ShowChartResultMemo form={chartForm} />
        </ManageTabsContextProvider>
      )}
    </List>
  );
};
