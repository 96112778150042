import ReactECharts from "echarts-for-react";
export const DistributionSessionCount: React.FC<{
  dataSource: any[];
}> = ({ dataSource }) => {
  const option: echarts.EChartsOption = {
    dataset: [
      {
        dimensions: ["variant", "min", "q1", "median", "q3", "max"],
        source: dataSource,
      },
      {
        transform: [
          {
            type: "sort",
            config: { dimension: "variant", order: "asc" },
          },
        ],
      },
    ],

    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        animation: false,
      },
    },
    grid: {
      left: "10%",
      top: "10%",
      right: "10%",
      bottom: "30%",
    },
    xAxis: {
      type: "category",
      name: "Variant",
      show: true,
      axisLabel: { rotate: 45 },
    },
    yAxis: {
      name: "Session count", //TODO:
      type: "value",
      nameLocation: "middle",
      nameGap: 70,
      splitArea: {
        show: true,
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    series: [
      {
        type: "boxplot",
        itemStyle: {
          color: "#b8c5f2",
        },
        datasetIndex: 1,
        encode: {
          y: ["min", "q1", "median", "q3", "max"],
          x: "variant",
          itemName: ["variant"],
          tooltip: ["min", "q1", "median", "q3", "max"],
        },
      },
    ],
  };
  return <ReactECharts option={option} style={{ height: "100%" }} />;
};
