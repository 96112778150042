import { shortenDimensionsQueryStep } from "./query-utils";
import { abUsersQuery } from "../multi-step-query";
import { leftJoinQueryStep, fillZeroNumericMetricQueryStep, aggregateQueryStep } from "../multi-step-query/query-utils";
import { getDateRangeWithTime } from "@src/util/time-form";
import { USER_COUNT_CONSTANT } from "@src/constant/ab-testing/user-count";

export const DEFAULT_NORMALIZED_USERS_QUERY_ALIAS = "normalized-users";
export const DEFAULT_USER_LEVEL_METRICS_QUERY_ALIAS = "user-level-metrics";
export const DEFAULT_NORMALIZED_USER_LEVEL_METRICS_QUERY_ALIAS = "normalized-user-level-metrics";

export function factUserSessionCountMetricQuery({
  queryStepAlias = DEFAULT_USER_LEVEL_METRICS_QUERY_ALIAS,
  timeRange,
  customTimeRange,
  amaAppIds,
  productCode,
}: {
  queryStepAlias?: any;
  timeRange?: any;
  customTimeRange?: any;
  amaAppIds?: string[];
  productCode: any;
}) {
  const metricFilters = [
    {
      member: "firebase.event_name",
      operator: "equals",
      values: ["session_start"],
    },
  ];

  if (amaAppIds) {
    metricFilters.push({
      member: "firebase.ama_app_id",
      operator: "equals",
      values: amaAppIds,
    });
  }

  const primaryQueryStep = {
    adapter_name: "cube",
    alias: queryStepAlias,
    model_config: {
      json_query: {
        filters: metricFilters,
        timeDimensions: [
          {
            dimension: "firebase.event_timestamp",
            dateRange: getDateRangeWithTime(timeRange, customTimeRange),
          },
        ],
        dimensions: ["firebase.user_pseudo_id", "firebase.ama_app_id", "firebase.event_timestamp"],
        measures: ["firebase.event_count"],
      },
      product_code: productCode,
    },
  };

  const shortenFieldNamesQueryStep = shortenDimensionsQueryStep({
    queryStepAlias: `shorten-${queryStepAlias}`,
    sourceQueryStepAlias: primaryQueryStep.alias,
    shortenNameMapping: {
      ["firebase__user_pseudo_id"]: "user_pseudo_id",
      ["firebase__ama_app_id"]: "ama_app_id",
      ["firebase__event_timestamp"]: "event_timestamp",
      ["firebase__event_count"]: "session_count",
    },
  });

  return {
    primaryQueryStep,
    shortenFieldNamesQueryStep,
  };
}

export function factUserTotalPlaytimeMetricQuery({
  queryStepAlias = DEFAULT_USER_LEVEL_METRICS_QUERY_ALIAS,
  timeRange,
  customTimeRange,
  amaAppIds,
  productCode,
}: {
  queryStepAlias?: any;
  timeRange?: any;
  customTimeRange?: any;
  amaAppIds?: string[];
  productCode: any;
}) {
  const metricFilters = [
    {
      member: "firebase.event_name",
      operator: "equals",
      values: ["song_result"],
    },
  ];

  if (amaAppIds) {
    metricFilters.push({
      member: "firebase.ama_app_id",
      operator: "equals",
      values: amaAppIds,
    });
  }

  const primaryQueryStep = {
    adapter_name: "cube",
    alias: queryStepAlias,
    model_config: {
      json_query: {
        filters: metricFilters,
        timeDimensions: [
          {
            dimension: "firebase.event_timestamp",
            dateRange: getDateRangeWithTime(timeRange, customTimeRange),
          },
        ],
        dimensions: ["firebase.user_pseudo_id", "firebase.ama_app_id", "firebase.event_timestamp"],
        measures: ["firebase.total_playtime"],
      },
      product_code: productCode,
    },
  };

  const shortenFieldNamesQueryStep = shortenDimensionsQueryStep({
    queryStepAlias: `shorten-${queryStepAlias}`,
    sourceQueryStepAlias: primaryQueryStep.alias,
    shortenNameMapping: {
      ["firebase__user_pseudo_id"]: "user_pseudo_id",
      ["firebase__ama_app_id"]: "ama_app_id",
      ["firebase__event_timestamp"]: "event_timestamp",
      ["firebase__total_playtime"]: "total_playtime",
    },
  });

  return {
    primaryQueryStep,
    shortenFieldNamesQueryStep,
  };
}

export function factUserEngagementTimeMetricQuery({
  queryStepAlias = DEFAULT_USER_LEVEL_METRICS_QUERY_ALIAS,
  timeRange,
  productCode,
  amaAppIds,
  customTimeRange,
}: {
  queryStepAlias?: any;
  timeRange: any;
  customTimeRange?: any;
  amaAppIds?: string[];
  productCode: any;
}) {
  const metricFilters = [
    {
      member: "firebase.event_name",
      operator: "equals",
      values: ["user_engagement", "screen_view", "app_exception", "first_open"],
    },
  ];

  if (amaAppIds) {
    metricFilters.push({
      member: "firebase.ama_app_id",
      operator: "equals",
      values: amaAppIds,
    });
  }

  const primaryQueryStep = {
    adapter_name: "cube",
    alias: queryStepAlias,
    model_config: {
      json_query: {
        filters: metricFilters,
        timeDimensions: [
          {
            dimension: "firebase.event_timestamp",
            dateRange: getDateRangeWithTime(timeRange, customTimeRange),
          },
        ],
        dimensions: ["firebase.user_pseudo_id", "firebase.ama_app_id", "firebase.event_timestamp"],
        measures: ["firebase.total_engagement_time"],
      },
      product_code: productCode,
    },
  };

  const shortenFieldNamesQueryStep = shortenDimensionsQueryStep({
    queryStepAlias: `shorten-${queryStepAlias}`,
    sourceQueryStepAlias: primaryQueryStep.alias,
    shortenNameMapping: {
      ["firebase__user_pseudo_id"]: "user_pseudo_id",
      ["firebase__ama_app_id"]: "ama_app_id",
      ["firebase__event_timestamp"]: "event_timestamp",
      ["firebase__total_engagement_time"]: "total_engagement_time",
    },
  });

  return {
    primaryQueryStep,
    shortenFieldNamesQueryStep,
  };
}

export function userLevelMetricQuerySteps({
  productCode,
  usersQueryStepFn,
  factQueryStepFn,
  usersQueryStepKWargs,
  factQueryStepKWargs,
  metric,
}: {
  productCode: any;
  usersQueryStepFn: typeof abUsersQuery;
  factQueryStepFn:
    | typeof factUserSessionCountMetricQuery
    | typeof factUserEngagementTimeMetricQuery
    | typeof factUserTotalPlaytimeMetricQuery
    | typeof factUserEventCountQuery;
  usersQueryStepKWargs: any;
  factQueryStepKWargs: any;
  metric: {
    name: string;
    aggExpFn: (metricConditions: string[]) => string;
  };
}) {
  /*
        Logic below only work with firebase
    */
  const { primaryQueryStep: factQueryStep, shortenFieldNamesQueryStep: shortenFacQueryStep } = factQueryStepFn({
    productCode,
    ...factQueryStepKWargs,
  });

  const { primaryQueryStep: usersQueryStep, shortenFieldNamesQueryStep: shortenUsersQueryStep } = usersQueryStepFn({
    productCode,
    ...usersQueryStepKWargs,
  });

  /*
    TODO: use this when switching cohort including date to onboarding date
    const dayDiffExp = "TIMESTAMP_DIFF(event_timestamp, first_signal_date, DAY)"
    */
  const dayDiffExp = "TIMESTAMP_DIFF(event_timestamp, install_date_tzutc, DAY)";

  const combineFactWithUserInfoQueryStep = leftJoinQueryStep({
    queryStepAlias: "fact-user",
    leftQueryStepAlias: shortenUsersQueryStep.alias,
    rightQueryStepAlias: shortenFacQueryStep.alias,
    selectExp: (_1, _2) => `*, ${dayDiffExp} AS day_diff`,
    joinCondition: {
      using: () => ["ama_app_id", "user_pseudo_id"],
    },
  });

  const metricConditions = ["day_diff >= 0"];

  if(factQueryStepKWargs.maxDayDiff) {
    metricConditions.push(`day_diff <= ${factQueryStepKWargs.maxDayDiff}`)
  }

  const aggregateFactToUserLevelQueryStep = aggregateQueryStep({
    queryStepAlias: "user-playtime",
    sourceQueryStepAlias: combineFactWithUserInfoQueryStep.alias,
    groups: ["exp_group", "user_pseudo_id"],
    metrics: {
      // [metric.name]: `SUM(IF(${metricCondition.join(" AND ")}, total_engagement_time/1000, 0))`
      [metric.name]: metric.aggExpFn(metricConditions),
    },
  });

  const normalizeUserMetricsQueryStep = fillZeroNumericMetricQueryStep({
    queryStepAlias: "normalized-user-metrics",
    sourceQueryStepAlias: aggregateFactToUserLevelQueryStep.alias,
    metrics: [metric.name],
  });

  return {
    finalStep: normalizeUserMetricsQueryStep,
    allSteps: [
      usersQueryStep,
      shortenUsersQueryStep,
      factQueryStep,
      shortenFacQueryStep,
      combineFactWithUserInfoQueryStep,
      aggregateFactToUserLevelQueryStep,
      normalizeUserMetricsQueryStep,
    ],
  };
}

export function factUserEventCountQuery({
  queryStepAlias = DEFAULT_USER_LEVEL_METRICS_QUERY_ALIAS,
  eventName,
  eventParam,
  eventParamValues,
  timeRange,
  customTimeRange,
  installDate,
  customInstallDate,
  productCode,
  amaAppIds,
}: {
  queryStepAlias?: string;
  eventName: string;
  eventParam?: string;
  eventParamValues?: string[];
  timeRange?: any;
  customTimeRange?: any;
  installDate?: any;
  customInstallDate?: any;
  productCode: any;
  amaAppIds?: string[];
}) {
  const metricFilters = [
    {
      member: "firebase.event_name",
      operator: "equals",
      values: [eventName],
    },
  ];

  if (eventParam && eventParamValues) {
    metricFilters.push({
      member: eventParam,
      operator: "equals",
      values: eventParamValues,
    });
  }

  if (amaAppIds) {
    metricFilters.push({
      member: "firebase.ama_app_id",
      operator: "equals",
      values: amaAppIds,
    });
  }

  const timeDimensions = [
    {
      dimension: "firebase.event_timestamp",
      dateRange: getDateRangeWithTime(timeRange, customTimeRange),
    },
  ];

  if (installDate) {
    timeDimensions.push({
      dimension: USER_COUNT_CONSTANT.DIMENSIONS.USER_AB_TESTING_INSTALL_DATE,
      dateRange: getDateRangeWithTime(installDate, customInstallDate),
    });
  }

  const primaryQueryStep = {
    adapter_name: "cube",
    alias: queryStepAlias,
    model_config: {
      json_query: {
        filters: metricFilters,
        timeDimensions: timeDimensions,
        dimensions: ["firebase.user_pseudo_id", "firebase.ama_app_id", "firebase.event_timestamp"],
        measures: ["firebase.event_count"],
      },
      product_code: productCode,
    },
  };

  const shortenFieldNamesQueryStep = shortenDimensionsQueryStep({
    queryStepAlias: `shorten-${queryStepAlias}`,
    sourceQueryStepAlias: primaryQueryStep.alias,
    shortenNameMapping: {
      ["firebase__user_pseudo_id"]: "user_pseudo_id",
      ["firebase__ama_app_id"]: "ama_app_id",
      ["firebase__event_timestamp"]: "event_timestamp",
    },
  });

  return {
    primaryQueryStep,
    shortenFieldNamesQueryStep,
  };
}
