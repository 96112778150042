import { css } from "@emotion/react";
import { config } from "@src/config";
import { DATE_RANGE_CUSTOM_VALUE, DATE_RANGE_VALUE, DATE_RANGES } from "@src/config/query-builder";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { DatePicker, DatePickerProps, Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday);

const { RangePicker } = DatePicker;

export const TimeRangeEngagement: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { updateSearchUrl } = useABContext();
  const timeRangeWatch = Form.useWatch("time_range");

  const options = DATE_RANGES.map((m) => {
    return {
      label: m.title || m.value,
      value: m.value,
    };
  });

  const handleChange = (value: string) => {
    let startDate;
    let endDate;

    switch (value) {
      case DATE_RANGE_VALUE.TODAY:
        startDate = dayjs().format(config.DATE_FORMAT);
        endDate = dayjs().format(config.DATE_FORMAT);
        break;
      case DATE_RANGE_VALUE.YESTERDAY:
        startDate = dayjs().subtract(1, "day").format(config.DATE_FORMAT);
        endDate = dayjs().subtract(1, "day").format(config.DATE_FORMAT);
        break;
      case DATE_RANGE_VALUE.THIS_WEEK:
        startDate = dayjs().weekday(1).format(config.DATE_FORMAT); // Monday
        endDate = dayjs().format(config.DATE_FORMAT); // Today
        break;
      case DATE_RANGE_VALUE.THIS_MONTH:
        startDate = dayjs().date(1).format(config.DATE_FORMAT); // First day of the month
        endDate = dayjs().format(config.DATE_FORMAT); // Today
        break;
      case DATE_RANGE_VALUE.LAST_WEEK:
        startDate = dayjs().weekday(-6).format(config.DATE_FORMAT); // Last Monday
        endDate = dayjs().weekday(0).format(config.DATE_FORMAT); // Last Sunday
        break;
      case DATE_RANGE_VALUE.LAST_MONTH: {
        const lastMonth = dayjs().subtract(1, "month");
        const numDays = lastMonth.daysInMonth();
        startDate = dayjs(lastMonth).date(1).format(config.DATE_FORMAT); // First day of last month
        endDate = dayjs(lastMonth).date(numDays).format(config.DATE_FORMAT); // Last day of last month
        break;
      }
      case DATE_RANGE_VALUE.LAST_7_DAYS:
        startDate = dayjs().subtract(7, "days").format(config.DATE_FORMAT); // Last 7 days
        endDate = dayjs().format(config.DATE_FORMAT); // Today
        break;

      case DATE_RANGE_VALUE.LAST_14_DAYS:
        startDate = dayjs().subtract(14, "days").format(config.DATE_FORMAT); // Last 14 days
        endDate = dayjs().format(config.DATE_FORMAT); // Today
        break;

      case DATE_RANGE_VALUE.LAST_30_DAYS:
        startDate = dayjs().subtract(30, "days").format(config.DATE_FORMAT); // Last 30 days
        endDate = dayjs().format(config.DATE_FORMAT); // Today
        break;
      default:
        startDate = dayjs().format(config.DATE_FORMAT);
        endDate = dayjs().format(config.DATE_FORMAT);
    }
    if (value !== DATE_RANGE_CUSTOM_VALUE) {
      form.setFieldsValue({ [ChartFormFieldsEnum.START_DATE]: startDate, [ChartFormFieldsEnum.END_DATE]: endDate });
    }
  };
  const datePickersChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;
    form.setFieldsValue({ [ChartFormFieldsEnum.START_DATE]: startDate, [ChartFormFieldsEnum.END_DATE]: endDate });
  };

  const disabled45DaysDate: DatePickerProps["disabledDate"] = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 45;
    }

    return false;
  };

  return (
    <div className="flex min-w-[700px]">
      <Form.Item label="Time Range" name={ChartFormFieldsEnum.TIME_RANGE} required className="mb-1 ">
        <Select
          placeholder="Select time range"
          options={options}
          style={{
            width: 250,
          }}
          optionRender={(item) => {
            return item.label;
          }}
          onChange={() => {
            updateSearchUrl();
          }}
        />
      </Form.Item>
      {timeRangeWatch === DATE_RANGE_CUSTOM_VALUE && (
        <Form.Item
          css={css`
            .ant-form-item-label {
              opacity: 0;
              width: 0;
            }
          `}
          className="ml-2 "
        >
          <Form.Item label="Custom Time Range" name={ChartFormFieldsEnum.CUSTOM_TIME_RANGE} className="mb-0">
            <RangePicker
              style={{ width: 250 }}
              disabledDate={disabled45DaysDate}
              onChange={() => {
                updateSearchUrl();
              }}
            />
          </Form.Item>
        </Form.Item>
      )}
      {/* <Form.Item name={ChartFormFieldsEnum.START_DATE} hidden>
        <Input />
      </Form.Item>

      <Form.Item name={ChartFormFieldsEnum.END_DATE} hidden>
        <Input />
      </Form.Item> */}
    </div>
  );
};
