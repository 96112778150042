import { Form } from "antd";
import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { ABFilterFormContent } from "./form";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "../../enum";
import { FilterFormFields } from "../../types";
import React from "react";
import { ChartFormInstanceType, FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import dayjs from "dayjs";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import { FilterExplorationLayout } from "../layout/filter-exploration-layout";

type FilterExplorationProps = {
  form: FormInstance<FilterFormInstanceType>;
  chartFormInstance: FormInstance<ChartFormInstanceType>;
};
export const FilterExploration: React.FC<FilterExplorationProps> = ({ form, chartFormInstance }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const { data, isLoading: isLoadingGenerateToken } = useCustom({
    url: `${config.BACKEND_URL}/product/${productCodeWatch}/generate-cube-token`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !!productCodeWatch,
      onSuccess: (data) => {
        const token = data?.data?.jwt_token;
        if (token) {
          form.setFieldValue(FilterFormFieldsEnum.PRODUCT_TOKEN, token);
        }
      },
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const handleFinish = (values: FilterFormFields) => {
    chartFormInstance.setFieldsValue({
      [ChartFormFieldsEnum.FILTER_FIELDS]: values,
    });
  };

  const getInitValue = () => {
    const filterForm = getGroupFieldValue("filterForm") ?? {};
    return {
      ...filterForm,
      [FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]: filterForm?.[FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]?.map(
        (item: string) => dayjs(item),
      ),
      [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
    };
  };

  return (
    <FilterExplorationLayout form={form} isLoadingGenerateToken={isLoadingGenerateToken}>
      <CubeProvider cubeApi={cubejsApi}>
        <Form
          name={FORM_NAME.FILTER_FORM}
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={getInitValue()}
        >
          <ABFilterFormContent form={form} />
        </Form>
      </CubeProvider>
    </FilterExplorationLayout>
  );
};
